import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import styled from 'styled-components'
import { 
  type_nav_link, 
  breakpoint,  
  margin, 
  headerHeight, 
  headerHeightMobile, 
  footerHeight, 
  footerHeightMobile, 
  baseColor, 
  colorTransition, 
  opacityTransition, 
  blurTransition, 
  greyColor, 
  blackColor, 
  eyeSize 
} from '../styles/helpers'
import Clock from './clock'

const FooterWrapper = styled.footer`
  // padding: 0 calc(${margin} * 2) 0 calc(${margin} * 2);
  padding: 0 ${margin} 0 ${margin};
  width: 100%;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  display: flex;
  justify-content: space-between;
  position: fixed;
  height: ${footerHeight};
  bottom: 0;
  align-items: center;
  transition: ${opacityTransition};
  opacity: ${props => (props.loaded ? `1` : `0`)};
  // ... todo: systemize z-index/above/below
  z-index: 100;
  @media ${breakpoint.xs} {
    padding: 2px ${margin} ${margin} ${margin};
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: ${footerHeightMobile};
    background: rgb(255,255,255);
    background: linear-gradient(0deg,rgba(255,255,255,1) 0%,rgba(255,255,255,0.9) 50%,rgba(255,255,255,0) 100%);
  }
`

const FooterNavWrap = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  // ...added
  @media ${breakpoint.xs} {
    height: unset;
    width: 100%;
  }
`

const EyeBall = styled.div`
  position: relative;
  display: inline-block;
  border-radius: 50%;
  height: ${eyeSize};
  width: ${eyeSize};
  background: white;
  border: solid black 1px;
  margin-right: calc(${margin} * 2);
  margin-bottom: .25rem;
  // margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Pupil = styled.div`
 background: black;
 width: 70%;
 height: 70%;
 border-radius: 50%;
`

const FooterNav = styled.nav`
  height: 100%;
  display: flex;
  align-items: center;
  // ...added
  @media ${breakpoint.xs} {
    width: 100%;
    justify-content: space-between;
  }
  & a {
    ${type_nav_link};
    color: ${greyColor};
    transition: ${colorTransition};
    margin-right: calc(${margin} * 2);
    &:last-child {
      margin-right: 0;
    }
    @media (hover: hover) {
      &:hover {
        color: ${blackColor};
      }
    }
    &.active { 
      color: ${blackColor} !important;
    }
  }
`

// grouped to align right
const FooterNavLinks = styled.div`
  display: flex;
  flex-flow: row wrap;
`


const Footer = ({ location, ...props }) => {

  const [footerNavOpen, setFooterNavOpen] = useState(false)

  useEffect(() => {
    if (props.mobile && footerNavOpen) {
      setFooterNavOpen(false)
    }
  }, [location.pathname])

  const handleMouseOver = () => {
    if (!props.mobile && !props.touch) {
      setFooterNavOpen(true)
    }
  }

  const handleMouseLeave = () => {
    if (!props.mobile && !props.touch) {
      setFooterNavOpen(false)
    }
  }

  const handleOpen = () => {
    setFooterNavOpen(true)
  }

  const handleClose = () => {
    setFooterNavOpen(false)
  }

  const footerNav = 
    !props.mobile && !props.touch ? 
    footerNavOpen ?
      <FooterNav>
        <Link activeClassName='active' to = '/about'>About</Link>
        <Link activeClassName='active' to = '/in-situ'>In Situ</Link>
        {/* <a href = 'https://patreon.com/thedormantoffice' target='_blank'>Donate</a> */}
        <Link activeClassName="active" to = '/subscribe'>Subscribe</Link>
        <Link activeClassName="active" to = '/booking'>Booking</Link>
      </FooterNav>
    :
    <FooterNav footerNavOpen={false}>
      <Link class = 'active' onClick={handleOpen}>Menu</Link>
    </FooterNav>
    :
    footerNavOpen ?
      <FooterNav>
        <a class = 'active' onClick={(event) => handleClose()}>Close</a>
        <FooterNavLinks>
          <Link activeClassName='active' to = '/about'>About</Link>
          <Link activeClassName='active' to = '/in-situ'>In Situ</Link>
          <Link activeClassName="active" to = '/subscribe'>Subscribe</Link>
          <Link activeClassName="active" to = '/booking'>Booking</Link>
        </FooterNavLinks>
      </FooterNav>
    :
      <FooterNav footerNavOpen={false}>
        <a onClick={(event) => handleOpen()} class = 'active'>Menu</a>
      </FooterNav>
  
  
  
  const eye = !props.mobile && !props.touch ? 
    <EyeBall className='eyeball'>
      <Pupil className='pupil'></Pupil>
    </EyeBall>
  :
    false

  const clock = !props.mobile ?
    <Clock />
  :
  footerNavOpen ? false : <Clock />

  return (
    <FooterWrapper loaded={props.loaded}>
      <FooterNavWrap onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
        {eye}
        {footerNav}
      </FooterNavWrap>
      {clock}
    </FooterWrapper>
  )
}



Footer.propTypes = {
  location: PropTypes.object,
  mobile: PropTypes.bool, 
  touch: PropTypes.bool
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
