export const types = {
  SET_WINDOW_LOADED: "SET_WINDOW_LOADED",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  SET_PREV_PAGE: "SET_PREV_PAGE",
  INCREMENT_YEAR: "INCREMENT_YEAR",
  DECREMENT_YEAR: "DECREMENT_YEAR",
  SET_CURRENT_MONTH: "SET_CURRENT_MONTH",
  INCREMENT_MONTH: "INCREMENT_MONTH",
  DECREMENT_MONTH: "DECREMENT_MONTH",
  SET_CURRENT_DAY: "SET_CURRENT_DAY",
  SET_CURRENT_YEAR: "SET_CURRENT_YEAR",
  SET_MAX_YEAR: "SET_MAX_YEAR",
  SET_MIN_YEAR: "SET_MIN_YEAR",
  SET_EVENTS_HOVERED: "SET_EVENTS_HOVERED",
  UNSET_EVENTS_HOVERED: "UNSET_EVENTS_HOVERED",
  FREEZE_LINE: "FREEZE_LINE",
  UNFREEZE_LINE: "UNFREEZE_LINE",
  SHOW_POPUP: "SHOW_POPUP",
  HIDE_POPUP: "HIDE_POPUP",
  SET_MOBILE: "SET_MOBILE",
  UNSET_MOBILE: "UNSET_MOBILE",
  SET_TOUCH: "SET_TOUCH",
  SET_COOKIES_ACCEPTED: "SET_COOKIES_ACCEPTED",
  UNSET_COOKIES_ACCEPTED: "UNSET_COOKIES_ACCEPTED"
}