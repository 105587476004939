import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components';
import Time from '../utils/time'
import moment from 'moment'
import PortableText from "@sanity/block-content-to-react"
import serializer from "../utils/serializer"
import Subscribe from './subscribe'
import { 
  type_general, 
  breakpoint, 
  margin
} from '../styles/helpers'
import Text from './text'
moment.tz.setDefault('Europe/Amsterdam')

// padding left + margin is optical
const PopupWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
`

const PopupModal = styled.div`
  position: relative;
  width: 40%;
  height: auto;
  background: white;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  max-width: 600px;
  box-shadow: 2px 2px 20px 5px rgba(0,0,0,.15);
  @media ${breakpoint.xs} {
    width: calc(100% - (${margin} * 4));
  }
  @media ${breakpoint.landscape} {
    width: calc(100% - (${margin} * 4));
  }
  @media ${breakpoint.sm} {
    width: 70%;
  }
`

const PopupText = styled.div`
  margin-bottom: 1em;
`

const PopupCloseBtn = styled.div`
  ${type_general};
  align-self: flex-end;
  margin-bottom: 2rem;
  cursor: pointer;
`

const Popup = ({ location, ...props }) => {
  return (
    <PopupWrapper>
      <PopupModal>
        <PopupCloseBtn onClick={(event) => { props.hidePopup()}}>
          (CLOSE)
        </PopupCloseBtn>
        <PopupText>
          <Text imageSize={'small'} data={props.popupText} />
        </PopupText>
      </PopupModal>
    </PopupWrapper>
  )
}


export default Popup
