import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { 
  type_nav_link, 
  type_logo, 
  margin, 
  colorTransition, 
  greyColor, 
  breakpoint, 
  headerHeight, 
  headerHeightMobile
} from '../styles/helpers'
import styled, { keyframes } from 'styled-components';
import { CSSTransition } from "react-transition-group";
import Time from '../utils/time'
import moment from 'moment'
moment.tz.setDefault('Europe/Amsterdam')

const HeaderWrapper = styled.header`
  border-left: 0;
  border-right: 0;
  border-top: 0;
  height: ${headerHeight};
  opacity: 0;
  display: flex;
  align-items: center;
  position: relative;
`

const HomeButton = styled.a`
  // margin-left: ${margin};
  margin-left: .25rem;
  // 4px accounts for border bottom of header
  // padding-top: 5px;
  padding-top: ${margin};
  cursor: pointer;
  ${type_logo};
  height: 100%;
  color: black;
  display: flex;
  align-items: center;
`

const HeaderNav = styled.nav`
  padding-top: 2px;
  margin-right: ${margin};
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  & a {
    ${type_nav_link};
    color: ${greyColor};
    transition: ${colorTransition};
    line-height: 1;
    &:last-child {
      // margin-left: calc(${margin} * 2);
      margin-left: calc(${margin} * 2.5);
    }
    &:hover {
      color: black;
    }
    &[aria-current] { 
      color: black;
    }
    @media ${breakpoint.xs} {
      padding-top: 2px;
    }
  }
`


const Header = ({ location, ...props }) => {

  const handleClick = (month) => {
    if (location.search === '?project=true') {
      navigate(-1)
    }
    else {
      // reset when redirected back to root
      const delay = props.path === '/' ? 1 : 500
      navigate('/')
      setTimeout(() => {
        // would need to add /projects here too
        if (!props.path.includes('/projects') && !props.path.includes('/about') && !props.path.includes('/booking') && !props.path.includes('/in-situ') && !props.path.includes('/archive')) {
          props.setCurrentDay(0)
          props.setCurrentMonth(Time.parseMonth(moment()))
          props.setCurrentYear(Time.parseYear(moment()))
        }
        props.unfreezeLine()
      }, delay)
    }
  }

  const projectsTitle = props.mobile ? 'Summer School' : 'Summer School'
  // const projectsTitle = 'Projects'
  const projectsLink = '/projects/summer-school-2022'
  // const projectsLink = '/projects'

  const headerNav = props.mobile ?
    location.pathname === '/' || location.pathname.includes('/events') ? 
      (
        <HeaderNav>
          <Link to={projectsLink}>{projectsTitle}</Link>
        </HeaderNav>
      )
      :
      location.pathname.includes('/projects') ?
      (
        <HeaderNav>
          <Link to='/'>Calendar</Link>
        </HeaderNav>
      )
      :
      (
        <HeaderNav>
          <Link to={projectsLink}>{projectsTitle}</Link>
        </HeaderNav>  
      )
  :
  location.pathname === '/' || location.pathname.includes('/events') ? 
    (
      <HeaderNav>
        <Link to={projectsLink}>{projectsTitle}</Link>
      </HeaderNav>
    )
    :
    location.pathname.includes('/projects') ?
    (
      <HeaderNav>
        <Link to='/'>Calendar</Link>
      </HeaderNav>
    )
    :
    (
      <HeaderNav>
        <Link to='/'>Calendar</Link>
        <Link to={projectsLink}>{projectsTitle}</Link>
      </HeaderNav>
    )

  return (
    <HeaderWrapper>
      <HomeButton onClick={handleClick} to = '/'>
        the dormant office
      </HomeButton>
      {headerNav}
    </HeaderWrapper>
  )
}

Header.propTypes = {
  location: PropTypes.object,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
