import React, { useEffect } from "react"
import PortableText from "@sanity/block-content-to-react"
import serializer from "../utils/serializer"
import styled from 'styled-components'  
import { 
  baseColor, 
  greyColor, 
  blackColor, 
  grid, 
  blurTransition, 
  colorTransition, 
  TextWrap, 
  InfoWrap 
} from '../styles/helpers'

const Text = (props) => {

  useEffect(() => {
  //  console.log('Text component', props)
  }, [])  


  if (props.info) {
    return (
      <InfoWrap>
        <PortableText blocks={props.data} serializers={serializer} />
      </InfoWrap>
    )
  }
  else {
    return (
      <TextWrap imageSize={props.imageSize} background={props.background}>
        <PortableText blocks={props.data} serializers={serializer} />
      </TextWrap>
    )
  }
}

export default Text
