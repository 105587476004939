import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ReactMoment from 'react-moment'
import 'moment-timezone'
import moment from 'moment'
import months from '../utils/months'
import Time from '../utils/time'
import { TransitionGroup, CSSTransition, SwitchTransition } from "react-transition-group"
import List from './list'
import { 
  TimelineWrapper,
  TimelineLine, 
  TimelineNav,
  TimelineNavLeft,
  TimelineNavCenter,
  TimelineNavRight,
  TimelineYearBtn,
  TimelineNavMonth,
  TimelineNavMonthTop,
  TimelineNavMonthBottom,
  TimelineEvents,
  TimelineEventsInner
} from '../styles/timeline'

import styled from 'styled-components'  
import _ from 'underscore'
import { Motion, spring } from 'react-motion';
moment.tz.setDefault('Europe/Amsterdam')

// temp solution
const GradientBar = styled.div`
  position: absolute;
  width: 100%;
  height: 2.5rem;
  pointer-events: none;
  // ...addded
  background: linear-gradient(180deg,rgba(255,255,255,1) 0%,rgba(255,255,255,0.75) 75%,rgba(255,255,255,0) 100%);
  z-index: 3;
`

class Timeline extends React.Component {
  constructor(props) {
    super(props)
    this.line = React.createRef();
    this.timelineNav = React.createRef();
    this.state = {
      monthPos: 0,
      linePos: 0,
      showTimeline: true,
      mobileNavWidth: 0,
      prevYearHovered: false,
      nextYearHovered: false,
    };
  }

  componentDidMount() {
    
    const self = this


    // look out for this: may want to do on update rather than mount
    const eventDates = this.props.data.map(event => moment(event.node.date))

    
    // should maybe do this in layout init instead
    if (this.props.minYear === 0 && this.props.data.length > 0) {
      let minDate = moment.min(eventDates)
      this.props.setMinYear(Time.parseYear(minDate))
    }

    if (this.props.maxYear === 0 && this.props.data.length > 0) {
      let maxEventDate = moment.max(eventDates)
      // if the latest date is before this year, set the max year to the current year, otherwise set it to max date (which is current year or above)
      let maxDate = Time.checkPast(maxEventDate, moment()) ? moment() : moment.max(eventDates)
      this.props.setMaxYear(Time.parseYear(maxDate))
    }

    // throw to bottom of stack
    setTimeout(() => {
      // todo: update this when window width updates
      if (self.props.mobile) {
        const timelineNav = self.timelineNav.current
        const offsetWidth = timelineNav.offsetWidth
        self.setState({
          mobileNavWidth: offsetWidth
        })
      }
      else {
        let monthSegment = document.querySelector('[data-month="' + self.props.currentMonth.toString() + '"]')
        self.setMonthPos(monthSegment)
      }

    let resizeTimer
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(() => {
        if (!self.props.mobile) {
          let monthSegment = document.querySelector('[data-month="' + self.props.currentMonth.toString() + '"]')
          self.setMonthPos(monthSegment)
        }
      }, 250)
    })

    }, 1)
  }

  resetYear() {
    let janSegment = document.querySelector('[data-month="' + '1' + '"]')
    this.setMonthPos(janSegment) 
  }

  setMonthPos(el) {
    if (!this.props.mobile) {
      const offsetLeft = el.offsetLeft
      const offsetWidth = el.offsetWidth
      this.setState({
        monthPos: offsetLeft + (offsetWidth / 2)
      })
    }
  }

  returnMonthOffset() {
    const self = this
    let dataMonth = this.props.currentMonth.toString()
    let timelineSegment = document.querySelector('[data-month="' + dataMonth + '"]')
    let segmentChildren = timelineSegment.children 
    let childrenArray = Array.from(segmentChildren)
    
    let segmentPoint = childrenArray.filter((item) => {
      return item.getAttribute('data-day') === self.props.currentDay.toString()
    })
    
    return segmentPoint[0].offsetLeft
  }

  setLinePos() {
    const self = this
    let linePos = this.props.currentDay === 0 ? this.state.monthPos : self.returnMonthOffset()
    if (this.state.linePos !== linePos) {
      this.setState({
        linePos: linePos
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.currentDay !== this.props.currentDay) {
      // if (!this.props.lineFrozen && !this.props.mobile) {
      if (!this.props.mobile) {
        this.setLinePos()
      }
    }
    if (prevProps.currentMonth !== this.props.currentMonth && !this.props.mobile) {
      let monthSegment = document.querySelector('[data-month="' + this.props.currentMonth.toString() + '"]')
      this.setMonthPos(monthSegment)
    }
  }


  // do not re-render if location has changed
  // keep an eye on this
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.pathname !== this.props.pathname) {
      return false 
    }
    else {
      return true
    }
  }

  handleMonthClick(num, event) {
    if (this.props.pathname.includes('/events/')) {
      const self = this
      const target = event.taget
      navigate('/')
      setTimeout(() => {
        self.props.setCurrentMonth(num)
        self.props.setCurrentDay(0)
      }, 500)
    }
    else {
      this.props.setCurrentMonth(num)
      this.props.setCurrentDay(0)
      if (!this.props.mobile) {
        this.setMonthPos(event.target)
      }
    }
  }

  handleYearClick(inc) {
    if (this.props.pathname.includes('/events/')) {
      navigate('/')
    }
    if (inc) {
      if (this.props.nextYear <= this.props.maxYear) {
        this.props.incrementYear()
        this.props.setCurrentMonth(1)
        this.resetYear()
      }
      else {
        return false 
      }
    }
    else {
      if (this.props.currentYear > this.props.minYear) {
        this.props.decrementYear()
        this.props.setCurrentMonth(1)
        this.resetYear()
      }
      else {
        return false 
      }
    }
  }

  handlePrevYearEnter() {
    if (!this.state.prevYearHovered) {
      this.setState({
        prevYearHovered: true 
      })
    }
  }

  handlePrevYearLeave() {
    if (this.state.prevYearHovered) {
      this.setState({
        prevYearHovered: false 
      })
    }
  }

  handleMobileNavClick(inc) {

    // close event page
    navigate('/')

    if (inc) {
      // if in decemeber
      if (this.props.currentMonth === 12) {
        if (this.props.nextYear <= this.props.maxYear) {
          this.props.incrementYear()
          this.props.setCurrentMonth(1)
        }
        else {
          // could throw error here
          return false
        }
      } 
      else {
        this.props.incrementMonth()
      }
    }
    else {
        if (this.props.currentMonth === 1) {
        if (this.props.currentYear > this.props.minYear) {
          this.props.decrementYear()
          // go to end of prev year on mobile
          this.props.setCurrentMonth(12)
        }
        else {
          return false 
        }
      }
      else {
        this.props.decrementMonth()
        }
    }
  }


  validateMonth(dt) {
    return Time.parseYear(dt) === this.props.currentYear && Time.parseMonth(dt) === this.props.currentMonth
  }

  validateDay(d, m) {
    let strDay = d.toString()
    let strMonth = m.toString()
    let day = strDay.length === 1 ? 0 + strDay : strDay 
    let month = strMonth.length === 1 ? 0 + strMonth : strMonth
    let date = day + '.' + month + '.' + this.props.currentYear.toString()
    let timeStamp = moment(date, 'DD.MM.YYYY')

    let isValid = this.props.data.filter((event, key) => {
      return Time.formatDate(timeStamp) === moment(event.node.date).format('DD.MM.YYYY') 
    })
  
    return isValid.length > 0 ? 'valid' : 'invalid'
  }

  returnGridClass(m) {
    const monthObj = months.filter(function(month) {
      return month.number === m 
    })
    // when current month is 0 in global state (not yet set), prevent it from showing
    return this.props.currentMonth !== 0 ? monthObj[0].name.toLowerCase() : 'invalid'
  }

  returnMonths() {
    const self = this 
    if (this.props.mobile) {
      const currentMonth = months[this.props.currentMonth - 1]
      return (
        <TimelineNavMonth>
          <div data-month={currentMonth.number} className='top'>
            {
              currentMonth.days.map((day) => {
                return <div className={self.validateDay(day, currentMonth.number)}></div>
              })
            }
          </div>
          <div className={'bottom'}>{currentMonth.fullname + ' ' + this.props.currentYear}</div>
        </TimelineNavMonth>
      )
    }
    else {
      return months.map(function(month, i) {
        const bottomClass = month.number === self.props.currentMonth ? 'bottom active' : 'bottom'
        return (
          <TimelineNavMonth>
            <div data-month={month.number} className='top'>
              {
                month.days.map((day) => {
                  return <div data-day={day} className={self.validateDay(day, month.number)}></div>
                })
              }
            </div>
            <div className={bottomClass} onClick={(event) => self.handleMonthClick(i + 1, event)} key={i}>{month.name}</div>
          </TimelineNavMonth>
        )
      })
    }
  }

  render() {    
    const self = this 

    const listData = this.props.data.filter((event, key) => {
      return self.props.currentMonth === Time.parseMonth(event.node.date) && self.props.currentYear === Time.parseYear(event.node.date)
    })

    // must do this in a cleaner way...
    const timelineVisible = !self.props.loaded ? false : !self.state.showTimeline ? false : !this.props.pathname.includes('/about') && !this.props.pathname.includes('/booking') && !this.props.pathname.includes('/projects') && !this.props.pathname.includes('/subscribe') && !this.props.pathname.includes('/in-situ') && !this.props.pathname.includes('/archive') ? true : false 
    
    const leftBtnText = this.props.mobile ? '←' : this.props.currentYear
    const rightBtnText = this.props.mobile ? '→' : this.props.nextYear
    const timelineLeftNav = this.props.mobile ? <TimelineNavLeft onClick={(event) => _.throttle(self.handleMobileNavClick(false, 1000))}><TimelineYearBtn>{leftBtnText}</TimelineYearBtn></TimelineNavLeft> : <TimelineNavLeft><TimelineYearBtn isCurrent={true} isMin={this.props.currentYear === this.props.minYear} isMax={false} onClick={() => self.handleYearClick(false)}><span>{leftBtnText}</span><span>← {this.props.currentYear - 1}</span></TimelineYearBtn></TimelineNavLeft>
    const timelineRightNav = this.props.mobile ? <TimelineNavRight onClick={(event) => _.throttle(self.handleMobileNavClick(true), 1000)}><TimelineYearBtn>{rightBtnText}</TimelineYearBtn></TimelineNavRight> : <TimelineNavRight><TimelineYearBtn isMin={false} isMax={this.props.currentYear === this.props.maxYear} isCurrent={false} onClick={() => self.handleYearClick(true)}><span>{rightBtnText}</span><span>{this.props.currentYear + 1} →</span></TimelineYearBtn></TimelineNavRight>


    const enterSpeed = this.props.mobile ? 750 : 750
    const exitSpeed = this.props.mobile ? 500 : 750
    const transitionName = this.props.mobile ? 'fade-list-mobile': 'fade-list'
    const showLine = true 

    const timelineClickable = this.props.pathname === '/' ? true : false

    // slow prop was: slow={this.props.eventsHovered}
    const defaultLineColor = this.props.currentMonth === 9 && this.props.currentYear === 2021 ? false : true
    const conditionalLine = this.props.mobile ? false : <Motion defaultStyle={{left: 0}} style={{ left: spring(this.state.linePos)}}>{interpolatingStyle => <TimelineLine defaultColor={defaultLineColor} showLine={showLine} style={interpolatingStyle} slow={false} ref={this.line} />}</Motion>

    return (
      <TimelineWrapper 
        prevPage={this.props.prevPage}
        showTimeline={timelineVisible}
        timelineClickable={timelineClickable}
      >

        {conditionalLine}

        <TimelineNav isAbout={this.props.pathname.includes('/about') || this.props.pathname.includes('/booking')}>
          {timelineLeftNav}
          <TimelineNavCenter ref={this.timelineNav}>
            {this.returnMonths()}
          </TimelineNavCenter>
          {timelineRightNav}
        </TimelineNav>
        <GradientBar></GradientBar>

        <TimelineEvents>
            <SwitchTransition>
            <CSSTransition
              key={self.props.currentMonth}
              classNames={transitionName}
              in={true}
              timeout={{ enter: enterSpeed, exit: exitSpeed }}
              unmountOnExit
              >
              {/* todo: find better way of setting events true than using includes */}
              <TimelineEventsInner>
                <List 
                  mobile={this.props.mobile}
                  setCurrentDay={this.props.setCurrentDay}
                  setCurrentYear={this.props.setCurrentYear}
                  setCurrentMonth={this.props.setCurrentMonth}
                  freezeLine={this.props.freezeLine}
                  pathname={this.props.pathname}
                  key={self.props.currentMonth} 
                  linePos={self.state.linePos} 
                  data={listData} 
                  nameClass={self.returnGridClass(self.props.currentMonth)} 
                />
              </TimelineEventsInner>
            </CSSTransition>
            </SwitchTransition>
        </TimelineEvents>

      </TimelineWrapper>
    )
  }
}

export default React.memo(Timeline)
