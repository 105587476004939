import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components';
import Time from '../utils/time'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { CSSTransition } from "react-transition-group";
import { 
  type_body, 
  typeBody, 
  baseColor, 
  blackColor, 
  greyColor, 
  colorTransition, 
  opacityTransition, 
  blurTransition 
} from '../styles/helpers'


const SubscribeWrap = styled.div`
  position: relative;
  height: 2rem;
`

const SubscribeForm = styled.form`
  position: absolute;
  top: 0;
  transition: ${opacityTransition};
  height: 100%;
  pointer-events: ${props => (props.isSubmitted ? `none` : `all`)};
  opacity: ${props => (props.isSubmitted ? `0` : `1`)};
  display: flex;
  align-items: center;
`

// padding-top is optical
const FormWrap = styled.div`
  height: 100%;
  border: solid black 1px;
  display: flex;
  &:first-child {
    margin-right: .25rem;
  }
  & input {
    padding: 2px .25rem 0 .25rem;
    outline: none;
    ${type_body};
    &:focus{
      outline: none;
    }
  }
  & button {
    ${type_body};
    padding: 2.5px .6rem 0 .5rem;
    outline: none;
    // same as colorTransition
    transition: background .25s linear;
    &:focus {
      outline: none;
    }
    @media (hover: hover) {
      &:hover {
        background: ${blackColor};
        color: ${baseColor};
      }
    }
  }
`

const SubscribeMsg = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  transition: ${opacityTransition};
  pointer-events: ${props => (props.isSubmitted ? `all` : `none`)};
  opacity: ${props => (props.isSubmitted ? `1` : `0`)};
  display: flex;
  align-items: center;
`

const SubscribeMsgInner = styled.div`
  padding-top: 1px;
`

const FormBackBtn = styled.div`
  display: inline-block;
  transition: ${colorTransition};
  color: ${blackColor};
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      color: ${greyColor};
    }
  }
`

class Subscribe extends React.Component {
  constructor(props) {
    super(props)
    this.successMsg = 'Thanks for subscribing!'
    this.placeholderMsg = 'Email Address'
    this.subscribedMsg = 'You are already subscribed!'
    this.invalidMsg = 'Please use a valid email address'
    this.inputRef = React.createRef()
    this.state = {
      email: '',
      isSubmitted: false,
      isSuccesful: false,
      isSubscribed: false,
      responseText: '',
      placeholder: this.placeholderMsg
    }
  }

  handleEmailChange(e) {
    this.setState({
      email: e.currentTarget.value
    })
  }

  handleFocus(e) {
    this.setState({
      placeholder: ''
    })
  }

  handleBlur(e) {
    this.setState({
      placeholder: this.placeholderMsg
    })
  }

  resetForm(e) {
    this.setState({
      email: '',
      isSubmitted: false,
      isSuccesful: false,
      isSubscribed: false,
      responseText: '',
      placeholder: this.placeholderMsg
    })
    this.inputRef.current.blur()
  } 

  handleSubmit(e, email) {
    const self = this
    e.preventDefault()
    addToMailchimp(email)
      .then((data) => {
        self.setState({
          isSubmitted: true
        })
        if (data.result === 'error') {
          // gatsby-plugin-mailchimp does not return status/specific callback
          // so having to read string instead
          if (data.msg.includes('already subscribed')) {
            self.setState({
              responseText: self.subscribedMsg,
              isSubscribed: true
            })
          }
          else {
            self.setState({
              responseText: self.invalidMsg
            })
          }
        }
        else {
          self.setState({
            responseText: self.successMsg,
            isSuccesful: true
          })
        }
      })
      .catch((error) => {
        self.setState({
          email: '',
          isSubmitted: false
        })
        return false
      });
  }

  render() {
    const self = this
    const returnBtn = !this.state.isSuccesful && !this.state.isSubscribed ? 
      <FormBackBtn onClick={(event) => self.resetForm(event)}>← Try Again</FormBackBtn> 
      : 
    false

    return (
      <SubscribeWrap>
        <SubscribeForm isSubmitted={this.state.isSubmitted} onSubmit={(event) => { self.handleSubmit(event, self.state.email)}}>
          <FormWrap>
            <input ref={this.inputRef} onBlur={(event) => { self.handleBlur(event)}} onFocus={(event) => { self.handleFocus(event)}} placeholder={this.state.placeholder} name="email" type="text" onChange={(event) => { self.handleEmailChange(event)}} />
          </FormWrap>
          <FormWrap>
            <button type='submit'>Subscribe</button>
          </FormWrap>
        </SubscribeForm>
        <SubscribeMsg isSubmitted={this.state.isSubmitted}>
          <SubscribeMsgInner>
            <div>{this.state.responseText}</div>
            {returnBtn}
          </SubscribeMsgInner>
        </SubscribeMsg>
      </SubscribeWrap>
    )
  }
}


export default Subscribe
