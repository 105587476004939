import styled, { css } from "styled-components";
import { 
  type_title, 
  type_title_sm, 
  type_general, 
  type_label, 
  type_body,
  type_body_bold,
  breakpoint, 
  baseColor, 
  greyColor, 
  blackColor, 
  grid, 
  margin, 
  colorTransition, 
  opacityTransition, 
  blurTransition, 
  blurTransitionMobile, 
  headerHeightMobile 
} from './helpers'

export const TimelineWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
  top: 0;
  position: absolute;
  width: 100%;
  transition: ${opacityTransition};
  transition-delay: ${props => (props.prevPage.includes('/about') || props.prevPage.includes('/booking') || props.prevPage.includes('/projects') || props.prevPage.includes('/in-situ') || props.prevPage.includes('/subscribe') || props.prevPage.includes('/archive') ? '.5s' : '0')};
  opacity: ${props => (props.showTimeline ? '1' : '0')};
  pointer-events: ${props => (props.showTimeline && props.timelineClickable ? 'all' : 'none')};
  border-left: 0;
  border-right: 0;
  border-top: solid black 2px;
  // border-bottom: solid black 2px;
  // ...removed
  z-index: 1;
  z-index: ${props => (props.showTimeline ? 10 : 1)};

`

export const TimelineLine = styled.div`
  background: ${props => (props.defaultColor ? '#d2d2d2' : '#ff0077')};
  height: 100%;
  position: absolute;
  z-index: 5;
  width: 2px;
  pointer-events: none;
  transition: opacity .25s linear .25s, background .5s linear;
  opacity: ${props => (props.showLine ? '1' : '0')};
  // temp?
  @media ${breakpoint.xs} {
    display: none;
  }
`

export const TimelineNav = styled.div`
  width: 100%;
  ${grid};
  z-index: 4;
  max-height: 2.5rem;
  pointer-events: ${props => (props.isAbout ? 'none' : 'all !important')};
  @media ${breakpoint.xs} {
    position: fixed;
  }
`

export const TimelineNavLeft = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media ${breakpoint.xs} {
    align-items: center;
    border-right: solid 2px black;
    grid-column-start: 1;
    grid-column-end: 3;
  }
`

export const TimelineNavCenter = styled.div`
  grid-column-start: 2;
  grid-column-end: 12;
  display: flex;
  // background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  @media ${breakpoint.xs} {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    grid-column-start: 3;
    grid-column-end: 11;
  }
`

export const TimelineNavRight = styled.div`
  grid-column-start: 12;
  grid-column-end: 13;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media ${breakpoint.xs} {
    align-items: center;
    border-left: solid 2px black;
    grid-column-start: 11;
    grid-column-end: 13;
  }
`

export const TimelineYearBtn = styled.div`
  ${type_label};
  transition: ${colorTransition};
  user-select: none;
  color: ${props => (props.isCurrent ? blackColor : greyColor)};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & span:last-child {
    display: none;
  }
  @media (hover: hover) {
    &:hover {
      & span {
        &:first-child {
          display: ${props => (props.isMax || props.isMin ? 'flex' : 'none')};
        }
        &:last-child {
          display: ${props => (props.isMax || props.isMin ? 'none' : 'flex')};
        }
      }
      cursor: ${props => (props.isMax || props.isMin ? 'default' : 'pointer')};
      color: ${props => (props.isMax && !props.isMin || props.isCurrent && !props.isMin ? greyColor : blackColor)};
    }
  }
  @media ${breakpoint.xs} {
    font-size: 120%;
    margin-top: 2px;
    color: ${blackColor};
  }
`

export const TimelineNavMonth = styled.div`
  flex-basis: 8.333%;
  width: 8.333%;
  @media ${breakpoint.xs} {
    width: 100%;
    flex-basis: unset;
    flex-shrink: 0;
  }
  &:first-child .top {
    border-left: solid 2px black;
    @media ${breakpoint.xs} {
      border: unset;
    }
  }
  & .top {
    height: 1rem;
    border: solid 2px black;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 0 1px 0 1px;
    & .invalid {
      opacity: 0;
      width: 1px;
      max-width: 3.333%;
      height: 50%;
    }
    & .valid {
      background: black;
      width: 1px;
      max-width: 3.333%;
      height: 50%;
    }
    @media ${breakpoint.xs} {
      border: unset;
    }
  }
  & .bottom {
    height: auto;
    text-align: center;
    ${type_label};
    transition: ${colorTransition};
    text-transform: uppercase;
    color: ${greyColor};
    user-select: none;
    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        color: ${blackColor};
      }
    }
    &.active {
      color: ${blackColor};
      @media ${breakpoint.xs} {
        color: ${blackColor};
      }
    }
    @media ${breakpoint.xs} {
      color: ${blackColor};
    }
  }
`

export const TimelineEvents = styled.div`
  padding: 2rem 0 0 0;
  ${grid};
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  @media ${breakpoint.xs} {
    padding: 4rem 0 0 0;
  }
  // &::-webkit-scrollbar {
  //   display: none;
  // }
`

export const TimelineEventsInner = styled.div`
grid-column-start: 2;
grid-column-end: 12;
@media ${breakpoint.xs} {
  grid-column-start: 3;
  grid-column-end: 11;
}
`

// ...event
export const EventItem = styled.li`
  padding-top: 0;
  padding-right: calc(${margin} * 1.6);
  // padding-bottom: 4rem;
  padding-bottom: 3rem;
  padding-left: 0;
  & a {
    color: ${greyColor};
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    backface-visibility: hidden;
    will-change: filter, color;
    transition: color .25s linear, filter .3s linear;
    @media ${breakpoint.xs} {
      color: ${props => (props.isPast ? greyColor : blackColor)};
      will-change: filter, opacity, color;
      transition: opacity .25s linear, filter .25s linear .25s;
    }
    @media ${breakpoint.xs} {
      padding: 0;
    }
    &.blurred {
      transition: color .25s linear, filter .3s linear .25s;
      filter: blur(5px);
      pointer-events: none;
      @media ${breakpoint.xs} {
        transition: opacity .25s linear .25s, filter .25s linear;
        filter: blur(5px);
        opacity: .3;
      }
    }
    &.active {
      color: black;
    }
    &.locked {
      pointer-events: none;
    }
    @media (hover: hover) {
      &:hover {
        color: black;
      }
    }
  }
`

// for mobile only
export const EventHeader = styled.div`
  padding: 0;
  margin-bottom: 2rem;
`

export const EventDate = styled.div`
  ${type_label};
  // ...was 1em previously
  margin-bottom: .75em;
`

export const EventTitle = styled.div`
  ${type_title_sm};
  display: block;
  @media ${breakpoint.xs} {
    ${type_body_bold};
  }
`

export const EventTimetable = styled.div`
  margin-top: 1rem;
`

export const EventTimetableEntry = styled.div`
  margin-bottom: 1rem;
`

export const EventDetail = styled.div`
  ${type_label};
  display: block;
  white-space: pre-wrap;
  @media ${breakpoint.xs} {
    font-size: 100%;
  }
`