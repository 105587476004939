import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import 'moment-timezone'
import moment from 'moment'
import Time from '../utils/time'
import { 
  type_nav_link, 
  margin 
} from '../styles/helpers'
moment.tz.setDefault('Europe/Amsterdam')

const ClockText = styled.div`
  ${type_nav_link};
  flex-shrink: 0;
`

const Clock = props => {
  const [currentTime, setCurrentTime] = useState(new Date())

  useEffect(() => {
    setInterval(() => {
      setCurrentTime(new Date())
    }, 1000)
  }, [])

  const returnCurrentDate = () => {
    return Time.formatDate(moment())
  }

  const returnCurrentTime = () => {
    let time = currentTime
    let hours = time.getHours()
    let minutes = time.getMinutes()
    let seconds = time.getSeconds()
    minutes = (minutes < 10 ? '0' : '') + minutes
    seconds = (seconds < 10 ? '0' : '') + seconds
    return hours + ':' + minutes + ':' + seconds
  }

  return (
    <React.Fragment>
      <ClockText>{returnCurrentDate()} {returnCurrentTime()}</ClockText>
    </React.Fragment>
  )
}

export default Clock
