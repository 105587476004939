import Time from '../utils/time'
import moment from 'moment'
moment.tz.setDefault('Europe/Amsterdam')

// initial state
const initialState = {
  windowLoaded: false,
  prevPage: '',
  currentPage: '',
  // hardcoding this for now
  currentYear: 2022,
  nextYear: 2023,
  // these get set on data request
  maxYear: 0,
  minYear: 0,
  currentMonth: 0,
  currentDay: 0,
  eventsHovered: false,
  lineFrozen: false,
  popUp: false,
  mobile: false,
  touch: false,
  cookiesAccepted: true
}

export default initialState