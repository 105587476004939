import 'moment-timezone'
import moment from 'moment'
moment.tz.setDefault('Europe/Amsterdam')

const Time = {
  parseDay(dt) {
    return parseInt(moment(dt).format('DD'))
  },
  parseMonth(dt) {
    return parseInt(moment(dt).format('M'))
  },
  parseYear(dt) {
    return parseInt(moment(dt).format('YYYY'))
  },
  formatDate(ts) {
    return moment(ts).format('DD.MM.YYYY')
  },
  checkPast(dt) {
    return moment(dt).isBefore(moment()) 
  },
  checkPresent(start, end) {
    return !(moment().isBefore(start) || moment().isAfter(end))
  },
  checkFuture(dt) {
    return moment(dt).isAfter(moment()) 
  }
};

export default Time