import { types } from "./types";
import initialState from './initialState'

export const rootReducer = (state = {initialState}, action) => {
  switch (action.type) {
    case types.SET_WINDOW_LOADED:
      return Object.assign({}, state, {
        windowLoaded: true,
      })
    case types.SET_CURRENT_PAGE:
      return Object.assign({}, state, {
        currentPage: action.payload,
      })
    case types.SET_PREV_PAGE:
      return Object.assign({}, state, {
        prevPage: action.payload,
      })
    case types.INCREMENT_YEAR:
      return Object.assign({}, state, {
        currentYear: state.currentYear += 1,
        nextYear: state.nextYear += 1
      })
    case types.DECREMENT_YEAR:
      return Object.assign({}, state, {
        currentYear: state.currentYear -= 1,
        nextYear: state.nextYear -= 1
      })
    case types.SET_CURRENT_MONTH:
      return Object.assign({}, state, {
        currentMonth: action.payload
      })
    case types.INCREMENT_MONTH:
      return Object.assign({}, state, {
        currentMonth: state.currentMonth += 1
      })
    case types.DECREMENT_MONTH:
      return Object.assign({}, state, {
        currentMonth: state.currentMonth -= 1
      })
    case types.SET_CURRENT_DAY:
      return Object.assign({}, state, {
        currentDay: action.payload
      })
    case types.SET_CURRENT_YEAR:
      return Object.assign({}, state, {
        currentYear: action.payload,
        nextYear: action.payload += 1
      })
    case types.SET_MAX_YEAR:
      return Object.assign({}, state, {
        maxYear: action.payload
      })
    case types.SET_MIN_YEAR:
      return Object.assign({}, state, {
        minYear: action.payload
      })
    case types.SET_EVENTS_HOVERED:
      return Object.assign({}, state, {
        eventsHovered: true
      })
    case types.UNSET_EVENTS_HOVERED:
      return Object.assign({}, state, {
        eventsHovered: false
      })
    case types.FREEZE_LINE:
      return Object.assign({}, state, {
        lineFrozen: true
      })
    case types.UNFREEZE_LINE:
      return Object.assign({}, state, {
        lineFrozen: false
      })
    case types.SHOW_POPUP:
      return Object.assign({}, state, {
        popUp: true
      })
    case types.HIDE_POPUP:
      return Object.assign({}, state, {
        popUp: false
      })
    case types.SET_MOBILE:
      return Object.assign({}, state, {
        mobile: true
      })
    case types.UNSET_MOBILE:
      return Object.assign({}, state, {
        mobile: false
      })
    case types.SET_TOUCH:
      return Object.assign({}, state, {
        touch: true
      })
    case types.SET_COOKIES_ACCEPTED:
      return Object.assign({}, state, {
        cookiesAccepted: true
      })
    case types.UNSET_COOKIES_ACCEPTED:
      return Object.assign({}, state, {
        cookiesAccepted: false
      })
    default:
      return state
  }
}