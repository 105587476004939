import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Image from "../components/image"
import Img from "gatsby-image"
import urlBuilder from "@sanity/image-url"
import { getFluidGatsbyImage, getFixedGatsbyImage } from 'gatsby-source-sanity'
import { 
  breakpoint,
  type_caption,
  type_nav_link, 
  margin 
} from '../styles/helpers'
import arrowNorth from "../static/images/arrow-north.svg"
import arrowSouth from "../static/images/arrow-south.svg"

const SlideshowWrapper = styled.div`
  display: flex;
`

const SlideWrap = styled.figure`
  display: flex !important;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  // ...added (85vh is optical)
  // this should be consistent for all images
  max-height: 85vh;
  & img {
    margin-bottom: .5em;
    // should not need important flag here
    // this should be consistent for all images
    object-fit: contain !important;
    cursor: ${props => (props.cursor === 'bwd' ? `w-resize` : `e-resize`)};
  }
  @media ${breakpoint.xs} {
    // should not need important flag here
    // width: 80% !important;
    width: 100% !important;
  }
  & figcaption {
    ${type_caption};
    white-space: pre-wrap;
    text-align: center;
    display: flex;
    align-items: center;
    transform: translateX(.5rem);
    & span#arrow {
      margin-left: .25rem;
      margin-top: .5rem;
      height: .7rem;
      width: .7rem;
      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
`

const Slideshow = props => {
  const [slideCount, setSlideCount] = useState(0)
  const [cursorBwd, setCursorBwd] = useState(0)
  // todo: move this config into an .env file or smthg
  const sanityConfig = { projectId: 'ul6999hy', dataset: 'production' }

  const returnSlides = (images) => {
    return images.map((img, key) => {
      return Slide(img.image.asset._ref, img.caption)
    })
  }

  const handleSlideMouseMove = (event) => {
    event.pageX >= (window.innerWidth / 2) ? setCursorBwd(false) : setCursorBwd(true) 
  }

  const Slide = (id, caption) => {
    const fluidProps = getFluidGatsbyImage(id, { maxWidth: 2000, quality: 100 }, sanityConfig)
    const src = urlBuilder(sanityConfig).image(id).url()
    const figcaption = props.officeImages ? <figcaption>{caption}<span id='arrow'><img src={slideCount === 0 ? arrowNorth : arrowSouth} /></span></figcaption> : <figcaption>{caption}</figcaption>
    return (
      <SlideWrap onMouseMove={handleSlideMouseMove} officeImages={props.officeImages} cursor={cursorBwd ? 'bwd' : 'fwd' } northArrow={slideCount === 0 ? true : false} onClick={(e) => handleSlideClick(e)}>
        <Img style={{ marginBottom: '.5em' }} fluid={fluidProps} />
        <figcaption>{figcaption}</figcaption>
      </SlideWrap>
    )
  }

  const handleSlideClick = (event) => {
    event.persist()
    const imgsLength = props.slides.length
    if (event.pageX >= (window.innerWidth / 2)) {
      slideCount >= (imgsLength - 1) ? setSlideCount(0) : setSlideCount(slideCount + 1)
    }
    else {
      slideCount <= 0 ? setSlideCount(imgsLength - 1) : setSlideCount(slideCount - 1)
    }
  }

  return (
    <React.Fragment>
      {/* {returnSlides(data.allSanityAbout.edges[0].node._rawOfficeImages)[slideCount] */}
      <SlideshowWrapper>{returnSlides(props.slides)[slideCount]}</SlideshowWrapper>
    </React.Fragment>
  )
}

// Slideshow.propTypes = {
//   images: PropTypes.node.isRequired,
// }

export default Slideshow
