import { createGlobalStyle } from "styled-components";
import { 
  breakpoint, 
  typeTitleLg, 
  baseColor, 
  greyColor, 
  blackColor 
} from './helpers'

export default createGlobalStyle`
  html,body {
    height: 100%;
    width: 100%;
    font-family: 'LarsRegular', Arial, sans-serif;
    background: ${baseColor};
    overflow: hidden;
    font-size: 14px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    @media ${breakpoint.sm} {
      user-select: none;
      font-size: 13px;
    }
    @media ${breakpoint.md} { 
      font-size: 14px;
    }
    @media ${breakpoint.lg} { 
      font-size: 14px;
    }
    @media ${breakpoint.xl} { 
      font-size: 14px;
    }
    @media ${breakpoint.xxl} {
      font-size: 14px;
    }
  }

  body {
    position: fixed;
  }

  * > b, * > strong {
    font-family: 'LarsBold';
    // ...added
    font-weight: 500;
  }

  * > a {
    text-decoration: none;
    user-select: none;
  }

  * > img {
    width: 100%;
  }

   *::-webkit-scrollbar {
    display: none;
    width: 0px;
    height: 0px;
    background: transparent;
  }

  
  #___gatsby,#gatsby-focus-wrapper {
    height: 100%;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }
  
  .slide-left-enter {
    transform: translateX(-100%);
    transition: transform 1.5s ease .5s;
    @media ${breakpoint.xs} {
      transition: transform 1s ease .5s;
    }
  }
  
  .slide-left-enter-active {
    transform: translateX(0);
    transition: transform 1.5s ease .5s;
    @media ${breakpoint.xs} {
      transition: transform 1s ease .5s;
    }
  }
  
  .slide-left-exit {
    transform: translateX(0);
    transition: transform 1.5s ease;
    @media ${breakpoint.xs} {
      transition: transform 1s ease;
    }
  }
  
  .slide-left-exit-active {
    transform: translateX(-100%);
    transition: transform 1.5s ease;
    @media ${breakpoint.xs} {
      transition: transform 1s ease;
    }
  }
  
  .slide-right-enter {
    transform: translateX(100%);
    transition: transform 1.5s ease .5s;
    @media ${breakpoint.xs} {
      transition: transform 1s ease .5s;
    }
  }
  
  .slide-right-enter-active {
    transform: translateX(0);
    transition: transform 1.5s ease .5s;
    @media ${breakpoint.xs} {
      transition: transform 1s ease .5s;
    }
  }
  
  .slide-right-exit {
    transform: translateX(0);
    transition: transform 1.5s ease;
    @media ${breakpoint.xs} {
      transition: transform 1s ease;
    }
  }
  
  .slide-right-exit-active {
    transform: translateX(100%);
    transition: transform 1.5s ease;
    @media ${breakpoint.xs} {
      transition: transform 1s ease;
    }
  }

  .fade-enter {
    opacity: 0;
    transition: opacity .5s linear
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity .5s linear .25s;
  }
  
  .fade-exit {
    opacity: 1;
    transition: opacity .5s linear;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity .5s linear;
  }

  
  .fade-page-enter {
    opacity: 0;
    transition: opacity .5s linear
  }
  
  .fade-page-enter-active {
    opacity: 1;
    transition: opacity .5s linear .25s;
  }
  
  .fade-page-exit {
    opacity: 1;
    transition: opacity .5s linear;
  }
  
  .fade-page-exit-active {
    opacity: 0;
    transition: opacity .5s linear;
  }

  .fade-list-mobile-enter {
    opacity: 0;
    transition: opacity .5s linear
  }
  
  .fade-list-mobile-enter-active {
    opacity: 1;
    transition: opacity .5s linear .25s;
  }
  
  .fade-list-mobile-exit {
    opacity: 1;
    transition: opacity .5s linear;
  }
  
  .fade-list-mobile-exit-active {
    opacity: 0;
    transition: opacity .5s linear;
  }

  .fade-list-enter {
    opacity: 0;
    transition: opacity 1s ease;
  }
  
  .fade-list-enter-active {
    opacity: 1;
    transition: opacity 1s ease;
  }
  
  .fade-list-exit {
    opacity: 1;
    transition: opacity 1s ease;
  }
  
  .fade-list-exit-active {
    opacity: 0;
    transition: opacity 1s ease;
  }

  .slide-list-next-enter {
    opacity: 0;
    transform: translateX(100%);
    transition: transform .5s ease, opacity .5s linear .1s;
  }
  
  .slide-list-next-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: transform .5s ease, opacity .5s linear .1s;
  }
  
  .slide-list-next-exit {
    opacity: 1;
    transform: translateX(0);
    transition: transform .5s ease, opacity .25s linear;
  }
  
  .slide-list-next-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform .5s ease, opacity .25s linear;
  }

  .slide-list-prev-enter {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform .5s ease, opacity .5s linear .1s;
  }
  
  .slide-list-prev-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: transform .5s ease, opacity .5s linear .1s;
  }
  
  .slide-list-prev-exit {
    opacity: 1;
    transform: translateX(0);
    transition: transform .5s ease, opacity .25s linear;
  }
  
  .slide-list-prev-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: transform .5s ease, opacity .25s linear;
  }

  .header-appear {
    opacity: 0;
  }

  .header-appear-active {
    opacity: 1 !important;
    transition: opacity .5s ease-in 1s;
  }

  .header-appear-done {
    opacity: 1 !important;
    transition: opacity .5s ease-in 1s;
  }

  .popup-enter {
    transform: translateY(100%);
  }

  .popup-enter-active {
    transform: translateY(0);
    transition: transform 1s ease;
  }

  .popup-exit {
    transform: translateY(0);
  }

  .popup-exit-active {
    transform: translateY(-100%);
    transition: transform 1s ease .25s;
  }

  .cookies-enter {
    transform: translateY(100%);
  }

  .cookies-enter-active {
    transform: translateY(0);
    transition: transform .75s ease;
  }

  .cookies-exit {
    transform: translateY(0);
  }

  .cookies-exit-active {
    transform: translateY(100%);
    transition: transform .75s ease;
  }

`;
