import React from "react"
import { Provider } from "react-redux"
import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import { rootReducer } from "./app"
import initialState from "./initialState"

const middleware = [thunk]

const windowGlobal = typeof window !== "undefined" && window

const devtools =
  process.env.NODE_ENV === 'development' && windowGlobal.devToolsExtension
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f

export const store = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(...middleware), devtools)
)


export default ({ element }) => <Provider store={store}>{element}</Provider>
