import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"


const Page = props => {
  return (
    <>
      {props.page}
    </>
  )
}

export default Page
