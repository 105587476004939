import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { 
  type_body, 
  breakpoint, 
  margin, 
  baseColor, 
  blackColor, 
  greyColor, 
  colorTransition 
} from '../styles/helpers'

const CookiesWrap = styled.div`
  position: fixed;
  width: 100%;
  padding: 2rem;
  ${type_body};
  bottom: 0;
  background: ${baseColor};
  z-index: 4;
  box-shadow: 2px 2px 20px 5px rgba(0,0,0,.15);
  display: flex;
  justify-content: space-between;
  @media ${breakpoint.xs} {
    justify-content: unset;
    flex-flow: column;
  }
  @media ${breakpoint.sm} {
    justify-content: unset;
    flex-flow: column;
  }
`

const CookiesText = styled.div`
  ${type_body};
  & a {
    color: ${greyColor};
    text-decoration: underline;
    transition: ${colorTransition};
    &:hover {
      color: ${blackColor};
    }
  }
  @media ${breakpoint.xs} {
    margin-bottom: 1em;
  }
  @media ${breakpoint.sm} {
    margin-bottom: 1em;
  }
`

const CookiesBtn = styled.button`
  outline: none;
  transition: ${colorTransition};
  display: flex;
  &:hover {
    color: ${greyColor};
  }
`

const Cookies = props => {

  const handleClick = () => {
    localStorage.setItem('cookiesAccepted', true)
    props.setCookiesAccepted()
  }

  return (
    <CookiesWrap>
      <CookiesText>We use cookies on this website. By using this site, you agree to its use of cookies. Read more about this <a href='https://policies.google.com/technologies/cookies' target='_blank'>here</a>.</CookiesText>
      <CookiesBtn onClick={(event) => handleClick()}>OK</CookiesBtn>
    </CookiesWrap>
  )
}

export default Cookies
