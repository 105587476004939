import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components'  
import GlobalStyle from '../styles/global';
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import {  TransitionGroup, CSSTransition } from "react-transition-group"
import { useHistory } from "react-router-dom"
import { globalHistory } from "@reach/router"
import { Router, Redirect, Location } from "@reach/router"
import { connect, bindActionCreators } from "react-redux"
import * as actions from '../state/actions';
import Header from "./header"
import Page from "./page"
import Footer from "./footer"
import Timeline from "./timeline"
import Popup from './popup'
import Cookies from './cookies'
import months from '../utils/months'
import trackMouse from '../utils/eye'
import { 
  breakpoint, 
  grid, 
  headerHeight, 
  headerHeightMobile, 
  colorTransition, 
  opacityTransition, 
  blurTransition 
} from '../styles/helpers'
import "../styles/reset.css"
import "../styles/fonts.css"
import { propTypes } from '@sanity/block-content-to-react';
import Time from '../utils/time'
import moment from 'moment'
import isTouchDevice from 'is-touch-device'
moment.tz.setDefault('Europe/Amsterdam')

const MainWrapper = styled.main`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  transition: ${blurTransition};
  filter: ${props => (props.popUp ? `blur(5px)` : `blur(0)`)};
`

const MainWrapperInner = styled.div`
  position: absolute;
  // height: calc(100% - (${headerHeight} * 2));
  height: calc(100% - ${headerHeight});
  width: 100%;
  top: ${headerHeight};
  flex-grow: 1;
  transition: ${opacityTransition};
  opacity: ${props => (props.loaded ? `1` : `0`)};
  overflow-y: scroll;
  overflow-x: hidden;
  @media ${breakpoint.xs} {
    height: calc(100% - (${headerHeightMobile} * 2));
    top: ${headerHeightMobile};
  }
`

const PanelWrapper = styled.div`
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  right: ${props => (props.currentMonth <= 6 ? `0` : `unset`)};
  left: ${props => (props.currentMonth > 6 ? `0` : `unset`)};
`

const RouterWrapper = styled.div`
  width: 100%;
  height: 100%;
  ${grid};
  border-left: 0;
  border-right: 0;
  border-top: solid black 2px;
  // border-bottom: solid black 2px;
  overflow: ${props => (props.projectQuery ? `hidden` : `scroll`)};
  // ...added
  // pointer-events: ${props => (props.above ? `all` : `none`)};
`

const RouterWrapperInner = styled.div`
  grid-column-start: 1;
  grid-column-end: 13;
  height: 100%;
    // ...addded
  // z-index: 2;
  & .transition-wrap {
    height: 100%;
  }
  & .router-wrap {
    height: 100%;
  }
`


const Layout = props => {

  const [loaded, setLoaded] = useState(false)
  const [fade, setFade] = useState(false)
  const children = props.children 
  const location = props.location

  const data = useStaticQuery(graphql`
    {
      allSanityEvent {
        edges {
          node {
            id
            title
            detail
            date
            _rawSlug
            _id
            slug {
              _key
              _type
              current
            }
          }
        }
      }
      allSanitySite {
        edges {
          node {
            popupCondition
            _rawPopupText
          }
        }
      }
    }
  `)

  const currentPath = useRef(props.location.pathname)

  // init
  useEffect(() => {
    // set current month + year on component mount
    let currentYear = Time.parseYear(moment())
    let currentMonth = Time.parseMonth(moment())
    props.setCurrentMonth(currentMonth)
    // nextYear state gets set on setCurrentYear
    props.setCurrentYear(currentYear)

    // mobile 
    window.innerWidth <= 640 ? props.setMobile() : props.unsetMobile()
    let resizeTimer
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(() => {
        window.innerWidth <= 640 ? props.setMobile() : props.unsetMobile()
      }, 250)
    })

    // touch 
    if (isTouchDevice()) {
      props.setTouch()
    }
    // this is a bit sketchy: would be better to do with true/false values from props
    else {
      if (window.innerWidth > 640) {
        window.addEventListener('mousemove', (event) => {
          const eye = document.querySelector('.eyeball')
          trackMouse(event, eye)
        })
      }
    }

    window.onload = () => {
      props.setWindowLoaded()
    }

  }, [])    


  useEffect(() => {
    if (props.location.pathname !== currentPath.current) {
      props.setPrevPage(currentPath.current)
      if (props.popUp) {
        props.hidePopup()
      }
    }
    currentPath.current = props.location.pathname
  }, [props.location.pathname])

  const handleCookies = () => {
    // get local storage
    if (localStorage.hasOwnProperty('cookiesAccepted')) {
      console.log('cookies accepted')
      if (data.allSanitySite.edges[0].node.popupCondition) {
        props.setCookiesAccepted()
        props.showPopup()
      }
    }
    else {
      props.unsetCookiesAccepted()
    }
  }


  // allow children (pages) to be passed with props
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { query: props.location.search, windowLoaded: props.windowLoaded, prevPage: props.prevPage, mobile: props.mobile, currentMonth: props.currentMonth, currentDay: props.currentDay, setCurrentYear: props.setCurrentYear, setCurrentMonth: props.setCurrentMonth, setCurrentDay: props.setCurrentDay })
  )

  const handleHeaderEnter = () => {
    setTimeout(() => {
      setLoaded(true)
      setTimeout(() => {
        handleCookies()
      }, 1000)
    }, 1000)
  }

  const handleCookiesExit = () => {
    setTimeout(() => {
      if (data.allSanitySite.edges[0].node.popupCondition) {
        props.showPopup()
      }
    }, 250)
  }

  // todo: render timeline conditionally based on page
  // const conditionalTimeline = props.location.pathname === '/' || props.location.pathname.includes('/events') ?
  //   <Timeline 
  //     loaded={loaded}
  //     data={data.allSanityEvent.edges} 
  //     currentMonth={props.currentMonth}
  //     currentYear={props.currentYear}
  //     currentDay={props.currentDay}
  //     nextYear={props.nextYear}
  //     minYear={props.minYear}
  //     maxYear={props.maxYear}
  //     mobile={props.mobile}
  //     prevPage={props.prevPage}
  //     setMinYear={props.setMinYear}
  //     setMaxYear={props.setMaxYear}
  //     setCurrentDay={props.setCurrentDay}
  //     setCurrentMonth={props.setCurrentMonth}
  //     setCurrentYear={props.setCurrentYear}
  //     incrementMonth={props.incrementMonth}
  //     decrementMonth={props.decrementMonth}
  //     incrementYear={props.incrementYear}
  //     decrementYear={props.decrementYear}
  //     freezeLine={props.freezeLine}
  //     pathname={props.location.pathname}/>
  // :
  // false


  const isProjectQuery = props.location.search === '?project=true'

  return (
    <React.Fragment>
      {/* styles */}
      <GlobalStyle />
      {/* popup */}
      <CSSTransition 
        classNames='popup'
        in={props.popUp}
        appear={true}
        timeout={{ enter: 1000, exit: 1250 }}
        unmountOnExit
      >
        <Popup popupText={data.allSanitySite.edges[0].node._rawPopupText} {...props} />
      </CSSTransition>
      {/* cookies */}
      <CSSTransition 
        classNames='cookies'
        in={!props.cookiesAccepted}
        appear={true}
        timeout={{ enter: 750, exit: 750 }}
        onExited={handleCookiesExit}
        unmountOnExit
      >
        <Cookies setCookiesAccepted={props.setCookiesAccepted} />
      </CSSTransition>
      {/* main */}
      <MainWrapper popUp={props.popUp}>
        {/* header */}
        <CSSTransition classNames='header' in enter={false} appear={true} timeout={500} onEntered={handleHeaderEnter}>
          <Header {...props} />
        </CSSTransition>
        {/* inner (between header + footer) */}
        <MainWrapperInner loaded={loaded}>
          {/* page wrapper (routes) */}
          <RouterWrapper
            id='view'
            projectQuery={isProjectQuery}
            above={props.location.pathname !== '/'}
          >
            <RouterWrapperInner>
              <Location className='location-wrap'>
                {({ location }) => (
                  <TransitionGroup className='transition-wrap'>
                    <CSSTransition 
                      key={location.pathname} 
                      // todo: fix transitions
                      classNames='fade-page'
                      timeout={{ enter: 750, exit: 500 }}
                    >
                      <Router className='router-wrap' key={props.location.pathname} location={props.location}>
                        <Page windowLoaded={props.windowLoaded} mobile={props.mobile} currentMonth={props.currentMonth} key={location.pathname} path={location.pathname} prevPage={props.prevPage} page={childrenWithProps} />
                      </Router>
                    </CSSTransition>
                  </TransitionGroup>
                )}
              </Location>
            </RouterWrapperInner>
          </RouterWrapper>
            {/* todo: render timeline conditionally based on page location */}
            <Timeline 
              loaded={loaded}
              data={data.allSanityEvent.edges} 
              currentMonth={props.currentMonth}
              currentYear={props.currentYear}
              currentDay={props.currentDay}
              nextYear={props.nextYear}
              minYear={props.minYear}
              maxYear={props.maxYear}
              mobile={props.mobile}
              prevPage={props.prevPage}
              setMinYear={props.setMinYear}
              setMaxYear={props.setMaxYear}
              setCurrentDay={props.setCurrentDay}
              setCurrentMonth={props.setCurrentMonth}
              setCurrentYear={props.setCurrentYear}
              incrementMonth={props.incrementMonth}
              decrementMonth={props.decrementMonth}
              incrementYear={props.incrementYear}
              decrementYear={props.decrementYear}
              freezeLine={props.freezeLine}
              pathname={props.location.pathname}
            />
        </MainWrapperInner>
        {/* footer */}
        <Footer loaded={loaded} mobile={props.mobile} touch={props.touch} location={props.location} />
      </MainWrapper>
    </React.Fragment>
  ) 
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  windowLoaded: state.windowLoaded,
  currentPage: state.currentPage,
  prevPage: state.prevPage,
  currentYear: state.currentYear,
  nextYear: state.nextYear,
  minYear: state.minYear,
  maxYear: state.maxYear,
  currentMonth: state.currentMonth,
  currentDay: state.currentDay,
  eventsHovered: state.eventsHovered,
  lineFrozen: state.lineFrozen,
  popUp: state.popUp,
  mobile: state.mobile,
  touch: state.touch,
  cookiesAccepted: state.cookiesAccepted
})


export default connect(mapStateToProps, actions)(Layout)
