import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Image from "../components/image"
import Video from "../components/video"
import Img from "gatsby-image"
import urlBuilder from "@sanity/image-url"
import { getFileAsset } from '@sanity/asset-utils';
import { getFluidGatsbyImage, getFixedGatsbyImage } from 'gatsby-source-sanity'
import { 
  breakpoint,
  type_caption,
  type_nav_link, 
  margin 
} from '../styles/helpers'


const VideoWrapper = styled.div`
  display: flex !important;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  max-height: 85vh;
  max-width: 500px;
  margin-bottom: 1rem;
  & video {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    margin-bottom: .5em;
  }
  @media ${breakpoint.xs} {
    width: 100% !important;
  }
  & figcaption {
    ${type_caption};
    white-space: pre-wrap;
    text-align: center;
    display: flex;
    align-items: center;
    transform: translateX(.5rem);
  }
`

const VideoComponent = props => {
  const [videoPlaying, setVideoPlaying] = useState(true)
  const [videoMuted, setVideoMuted] = useState(true)
  const data = props.data.node
  // todo: move this config into an .env file or smthg
  const sanityConfig = { projectId: 'ul6999hy', dataset: 'production' }
  const videoAsset = getFileAsset(data.video, sanityConfig)
  const caption = data.caption.length ? <figcaption>{data.caption}</figcaption> : false
  // ...

  return (
    <React.Fragment>
      <VideoWrapper>
        <video controls loop playsInline>
          <source src={videoAsset.url} type={`video/${videoAsset.extension}`} />
        </video>
        {caption}
      </VideoWrapper>
    </React.Fragment>
  )
}


export default VideoComponent
