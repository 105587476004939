import styled, { css, createGlobalStyle } from "styled-components";

// variabkes
// export const baseColor = 'white';
// export const greyColor = '#b2b2b2'
// export const blackColor = 'black'
// export const padding = '.25rem';
// export const headerHeight = '1.6rem';
// export const headerHeight = '2.35rem';
// this is 40px in design (2.85rem or smtng but appears too tight)
// export const headerHeight = '2.125rem';
// export const headerHeightMobile = 'calc(1.6rem + 2px)';
// same as header
// export const footerHeight = '2.125rem';
// export const footerHeightMobile = '2.125rem';
// export const margin = '.25rem'; 
// this is .75 in design, but appears too tight

// ...variables
export const baseColor = 'white';
export const greyColor = '#b2b2b2'
export const blackColor = 'black'
export const padding = '.25rem';
// export const headerHeight = '2.125rem';
export const headerHeight = '1.5rem';
export const footerHeight = '2.125rem';
// export const footerHeightMobile = '2.125rem';
export const footerHeightMobile = '4.5rem';
// export const footerHeightMobile = '6rem';
export const margin = '.5rem'; 
// would be ideal to make this 4rem, but does not work with scroll-margin-top
// ...fix this
export const verticalMargin = '5rem';
export const verticalMarginMobile = '3rem';
export const entryPadding = '12.5rem';
export const eyeSize = '18px';
export const opacityTransition = 'opacity .25s linear';
export const colorTransition = 'color .25s linear';
export const blurTransition = 'filter .25s linear';
export const blurTransitionMobile = 'filter .5s linear';

// breakpoints
const screens = {
  a: '640px',
  b: '768px',
  c: '1290px',
  d: '1440px',
  e: '1600px'
};

export const breakpoint = {
  xs: `(min-width: 0) and (max-width: ${screens.a})`,
  landscape: `(max-width: ${screens.b}) and (orientation: landscape)`,
  sm: `(min-width: 641px) and (max-width: ${screens.b})`,
  md: `(min-width: 769px) and (max-width: ${screens.c})`,
  lg: `(min-width: 1281px) and (max-width: ${screens.d})`,
  xl: `(min-width: 1441px) and (max-width: ${screens.e})`,
  xxl: `(min-width: 1601px)`
};

// layout
export const grid = css`
  display: grid; 
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: ${padding};
`

// typography (v2)
// note: all 1.3 line-heights were previously 1.4; testing this out
export const type_title = css`
  font-family: 'LarsBold';
  // ...added
  font-weight: 500;
  font-size: 1.85rem;
  line-height: 1.2;
  letter-spacing: -0.020em;
`;


export const type_title_md = css`
  font-family: 'LarsBold';
  // ...added
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.125;
  letter-spacing: -0.010em;
`;

export const type_title_sm = css`
  font-family: 'LarsBold';
  // ...added
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.125;
  letter-spacing: -0.010em;
`;

export const type_general = css`
  font-family: 'LarsRegular';
  font-size: 1rem;
  line-height: 1.2;
  letter-spacing: normal;
`;

export const type_general_lg = css`
  font-family: 'LarsRegular';
  font-size: 1.15rem;
  line-height: 1.25;
  letter-spacing: normal;
`;

export const type_logo = css`
  font-family: 'LarsBold';
  // ...added
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 1;
  letter-spacing: -0.030em;
`;

export const type_body = css`
  font-family: 'LarsRegular';
  font-size: 1rem;
  line-height: 1.3;
  letter-spacing: normal;
`;

export const type_body_bold = css`
  font-family: 'LarsBold';
  // ...added
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.3;
  letter-spacing: -0.010em;
`;

export const type_body_sm = css`
  font-family: 'LarsRegular';
  font-size: 0.85rem;
  line-height: 1.3;
  letter-spacing: normal;
`;

export const type_label = css`
  font-family: 'LarsRegular';
  font-size: 0.85rem;
  line-height: 1.25;
  letter-spacing: normal;
`;

export const type_nav_link = css`
  font-family: 'LarsRegular';
  font-size: 1rem;
  line-height: 1;
  // is -10 in design -- test this
  letter-spacing: normal;
  @media ${breakpoint.xs} {
    font-size: 110%;
  }
`;

export const type_caption = css`
  font-family: 'LarsRegular';
  font-size: .65rem;
  line-height: 1.2;
  letter-spacing: normal;
`;

export const FigureEl = styled.figure`
  // ...added (same as Slideshow in About)
  max-height: 85vh;
  & img {
    // ...added (same as slideshow in About)
    object-fit: contain !important;
  }
  & figcaption {
    text-align: center;
    ${type_caption};
  }
`

// transition should be same as any other box on site (e.g. booking links in index or in-situ links)
export const ActionButton = styled.button`
  border: solid black 1px;
  margin-bottom: 1em;
  display: flex;
  align-self: flex-start;
  background: none;
  opacity: ${props => (props.inactive ? .5 : 1)};
  cursor: ${props => (props.inactive ? 'not-allowed' : 'pointer')};
  &:hover {
    background: ${props => (props.inactive ? 'none !important' : 'black')};
    & a {
      color: ${props => (props.inactive ? 'black !important' : 'white !important')};
      cursor: ${props => (props.inactive ? 'not-allowed' : 'pointer')};
    }
  }
  transition: background .2s linear !important;
  & a {
    transition: color .2s linear !important;
    padding: .75rem .75rem .7rem .75rem;
    width: 100%;
    height: 100%;
    text-decoration: none !important;
    cursor: ${props => (props.inactive ? 'not-allowed' : 'pointer')};
  }
`

export const TextWrap = styled.div`
  ${type_body};
  margin-bottom: 1em;
  & div {
    flex-flow: column;
    display: flex;
    justify-content: center;
  }
  & * > figure {
    width: ${props => (props.imageSize === 'small' ? '25%' : props.imageSize === 'medium' ? '70%' : '100%')};
    align-self: ${props => (props.imageSize !== 'small' ? 'center' : 'flex-start')};
    max-width: ${props => (props.imageSize === 'small' ? '250px' : 'unset')};
    margin-bottom: 1em;
    & figcaption {
      text-align: center;
      ${type_label};
    }
    @media ${breakpoint.xs} {
      width: ${props => (props.imageSize === 'small' ? '30%' : '100%')};
    }
    @media ${breakpoint.sm} {
      width: ${props => (props.imageSize === 'small' ? '25%' : '100%')};
    }
  }
  & * > p {
    margin-bottom: 1em;
    ${type_body};
    &:last-child {
      margin-bottom: 0;
    }
    & strong {
      ${type_body_bold};
    }
    & a {
      color: black;
      text-decoration: underline;
      transition: ${colorTransition};
      @media (hover: hover) {
        &:hover {
          color: ${greyColor};
        }
      }
    }
  }
  & a {
    color: black;
    text-decoration: underline;
    transition: ${colorTransition};
    @media (hover: hover) {
      &:hover {
        color: ${greyColor};
      }
    }
  }
`

export const NewsletterWrap = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 3em;
  & h1 {
    ${type_body_bold};
    margin-bottom: 1em;
  }
  & span {
    margin-bottom: .75em;
  }
`