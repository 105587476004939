import { types } from "./types"

export const setWindowLoaded = () => (dispatch) => {
  dispatch({
    type: types.SET_WINDOW_LOADED
  })
}

export const setCurrentPage = (data) => (dispatch) => {
  dispatch({
    type: types.SET_CURRENT_PAGE,
    payload: data,
  })
}

export const setPrevPage = (data) => (dispatch) => {
  dispatch({
    type: types.SET_PREV_PAGE,
    payload: data,
  })
}

export const incrementYear = () => (dispatch) => {
  dispatch({
    type: types.INCREMENT_YEAR
  })
}

export const decrementYear = () => (dispatch) => {
  dispatch({
    type: types.DECREMENT_YEAR
  })
}

export const setCurrentMonth = (x) => (dispatch) => {
  dispatch({
    type: types.SET_CURRENT_MONTH,
    payload: x
  })
}

export const incrementMonth = () => (dispatch) => {
  dispatch({
    type: types.INCREMENT_MONTH
  })
}

export const decrementMonth = () => (dispatch) => {
  dispatch({
    type: types.DECREMENT_MONTH
  })
}

export const setCurrentDay = (x) => (dispatch) => {
  dispatch({
    type: types.SET_CURRENT_DAY,
    payload: x
  })
}

export const setCurrentYear = (x) => (dispatch) => {
  dispatch({
    type: types.SET_CURRENT_YEAR,
    payload: x
  })
}

export const setMaxYear = (x) => (dispatch) => {
  dispatch({
    type: types.SET_MAX_YEAR,
    payload: x
  })
}

export const setMinYear = (x) => (dispatch) => {
  dispatch({
    type: types.SET_MIN_YEAR,
    payload: x
  })
}

export const setEventsHovered = () => (dispatch) => {
  dispatch({
    type: types.SET_EVENTS_HOVERED
  })
}


export const unsetEventsHovered = () => (dispatch) => {
  dispatch({
    type: types.UNSET_EVENTS_HOVERED
  })
}

export const freezeLine = () => (dispatch) => {
  dispatch({
    type: types.FREEZE_LINE
  })
}

export const unfreezeLine = () => (dispatch) => {
  dispatch({
    type: types.UNFREEZE_LINE
  })
}

export const showPopup = () => (dispatch) => {
  dispatch({
    type: types.SHOW_POPUP
  })
}

export const hidePopup = () => (dispatch) => {
  dispatch({
    type: types.HIDE_POPUP
  })
}

export const setMobile = () => (dispatch) => {
  console.log('set mobile')
  dispatch({
    type: types.SET_MOBILE
  })
}

export const unsetMobile = () => (dispatch) => {
  console.log('unset mobile')
  dispatch({
    type: types.UNSET_MOBILE
  })
}

export const setTouch = () => (dispatch) => {
  dispatch({
    type: types.SET_TOUCH
  })
}

export const setCookiesAccepted = () => (dispatch) => {
  dispatch({
    type: types.SET_COOKIES_ACCEPTED
  })
}

export const unsetCookiesAccepted = () => (dispatch) => {
  dispatch({
    type: types.UNSET_COOKIES_ACCEPTED
  })
}