import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ReactMoment from 'react-moment'
import 'moment-timezone'
import moment from 'moment'
import _ from 'underscore'
import { navigate } from "gatsby"
import Time from '../utils/time'
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components'  
import { 
  type_general, 
  type_title_sm, 
  type_label, 
  breakpoint, 
  margin, 
  blackColor, 
  greyColor, 
  grid, 
  colorTransition, 
  blurTransition, 
  TextWrap 
} from '../styles/helpers'
import { 
  EventItem,
  EventDate,
  EventTitle,
  EventTimetable,
  EventTimetableEntry,
  EventDetail
} from '../styles/timeline'


moment.tz.setDefault('Europe/Amsterdam')

const EventList = styled.ul`
  list-style: none;
  position: relative;
  max-width: 16.6%;
  display: inline-flex;
  flex-flow: column;
  @media ${breakpoint.xs} {
    max-width: 100%;
  }
  &.invalid {
    opacity: 0;
    pointer-events: none;
  }
  &.jan {
    left: 0;
    @media ${breakpoint.xs} {
      left: unset;
    }
  }
  &.feb {
    left: 8.3%;
    @media ${breakpoint.xs} {
      left: unset;
    }
  }
  &.mar {
    left: 16.6%;
    @media ${breakpoint.xs} {
      left: unset;
    }
  }
  &.apr {
    left: 24.9%;
    @media ${breakpoint.xs} {
      left: unset;
    }
  }
  &.may {
    left: 33.2%;
    @media ${breakpoint.xs} {
      left: unset;
    }
  }
  &.jun {
    left: 41.5%;
    @media ${breakpoint.xs} {
      left: unset;
    }
  }
  &.jul {
    left: 49.8%;
    @media ${breakpoint.xs} {
      left: unset;
    }
  }
  &.aug {
    left: 58.1%;
    @media ${breakpoint.xs} {
      left: unset;
    }
  }
  &.sep {
    left: 66.4%;
    @media ${breakpoint.xs} {
      left: unset;
    }
  }
  &.oct {
    left: 74.7%;
    @media ${breakpoint.xs} {
      left: unset;
    }
  }
  &.nov {
    left: 83%;
    @media ${breakpoint.xs} {
      left: unset;
    }
  }
  &.dec {
    left: 91.9%;
    @media ${breakpoint.xs} {
      left: unset;
    }
  }
`

class List extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentMonth: 1,
      linkClicked: false
    }
  }

  componentDidMount() {
    // ...
  }

  handleLinkClick(event, link, m) {
    event.preventDefault()
    this.props.freezeLine()

    // timeout to throw to bottom of stack
    setTimeout(() => {
      navigate('/events/' + link, { state: { month: m }})
    }, 1)
  }

  returnSlug(evt) {
    // find the first slug for concatenated days
    let dayEvents = this.props.data.filter(x => Time.formatDate(x.node.date) == Time.formatDate(evt.date) && x.node.title === evt.title)
    let sortedDays = dayEvents.sort((a, b) => {
      return new Date(a.node.date) - new Date(b.node.date);
    })
    return sortedDays[1].node.slug.current 
  }
  

  returnClass(slug, evt, concatTitle) {
    if (!concatTitle) {
      if (this.props.pathname.includes('/events')) {
        return this.props.mobile ? 'blurred' : this.props.pathname.includes(slug) ? 'active' : 'blurred'
      }
      else {
        return Time.checkPast(evt.date) ? '' : 'active'
      }
    }
    else {
      if (this.props.pathname.includes('/events')) {
        return this.props.mobile ? 'blurred' : this.props.pathname.includes(slug) || this.props.pathname === '/events/' + this.returnSlug(evt) || this.props.pathname === '/events/' + this.returnSlug(evt) + '/' ? 'active locked' : 'blurred locked'
      }
      else {
        return Time.checkPast(evt.date) ? 'locked' : 'active locked'
      }
    }
  }

  isFirstEvent(evt) {
    let dayEvents = this.props.data.filter(x => Time.formatDate(x.node.date) == Time.formatDate(evt.date) && x.node.title === evt.title)
    let times = dayEvents.map(d => moment(d.node.date)) 
    let earliest = moment.min(times).format()
    return moment(evt.date).format() === earliest
  }

  // function to determine if there are more than 1 event of the same type (title)
  // on the same day
  hasMultipleEvents(evt) {
    // && if multiple selected
    let dayEvents = this.props.data.filter(x => Time.formatDate(x.node.date) == Time.formatDate(evt.date) && x.node.title === evt.title)
    return dayEvents.length > 1 
  }

  
  returnConcatEvents(evt) {
    const self = this 
    // return all events with this date and title here
    const dayEvents = this.props.data.filter(x => Time.formatDate(x.node.date) == Time.formatDate(evt.date) && x.node.title === evt.title)
    const sortedDays = dayEvents.sort((a, b) => {
      return new Date(a.node.date) - new Date(b.node.date);
    })
    // return the first day 
    const firstEvent = sortedDays[0].node
    const month = Time.parseMonth(firstEvent.date)

    const timetable = dayEvents.map((singleEvent, key) => {
      return (
        <EventTimetableEntry>
          <a onClick={(event) => self.handleLinkClick(event, singleEvent.node.slug.current, month)} className={self.returnClass(singleEvent.node.slug.current, singleEvent.node, false)}>
            <EventDetail>
              <ReactMoment format="HH:mm">
                {singleEvent.node.date}
              </ReactMoment>
            </EventDetail>
            {singleEvent.node.detail !== null ? <EventDetail>{sanitizeHtml(singleEvent.node.detail)}</EventDetail> : false}
          </a>
        </EventTimetableEntry>
      )
    })

    return (
      <EventItem pointerEvents={false} isPast={Time.checkPast(firstEvent.date)}>
        <a className={self.returnClass(firstEvent.slug.current, firstEvent, true)}>
          <EventDate>
            <ReactMoment format="DD.MM.YYYY">
              {firstEvent.date}
            </ReactMoment>
          </EventDate>
        <EventTitle>{firstEvent.title}</EventTitle>
        </a>
        <EventTimetable>
          {timetable}
        </EventTimetable>
      </EventItem>
    )
  }

  // return a single event, passing entry.node as (evt)
  returnSingleEvent(evt) {
    const month = Time.parseMonth(evt.date)
    return (
      <EventItem pointerEvents={true} isPast={Time.checkPast(evt.date)}>
        <a className={this.returnClass(evt.slug.current, evt, false)} onClick={(event) => this.handleLinkClick(event, evt.slug.current, month)}>
          <EventDate>
            <ReactMoment format="DD.MM.YYYY, HH:mm">
              {evt.date}
            </ReactMoment>
          </EventDate>
          <EventTitle>{evt.title}</EventTitle>
          {evt.detail !== null ? <EventDetail>{sanitizeHtml(evt.detail)}</EventDetail> : false}
        </a>
      </EventItem>
    )
  }


  render() {
    const self = this

    if (self.props.data.length === 0) {
      return (
        <EventList className={self.props.nameClass}>
        </EventList>
      )
    }
    else {
      return (
        <EventList className={self.props.nameClass}>
          {  
            self.props.data.sort((a, b) => new Date(a.node.date).getTime() - new Date(b.node.date).getTime()).map(function(entry, x) {
              if (self.hasMultipleEvents(entry.node) && self.isFirstEvent(entry.node)) {
                return self.returnConcatEvents(entry.node)
              }
              else if (!self.hasMultipleEvents(entry.node)) {
                return self.returnSingleEvent(entry.node)
              }
            })
          }
        </EventList>
      )
    }
  }
}

export default React.memo(List)