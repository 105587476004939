import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import urlBuilder from "@sanity/image-url"
import { getFluidGatsbyImage, getFixedGatsbyImage } from 'gatsby-source-sanity'

const Image = (id) => {
  // todo: move config data into .env file if still in use
  const sanityConfig = { projectId: 'ul6999hy', dataset: 'production' }
  const fluidProps = getFluidGatsbyImage(id, { maxWidth: 5000, quality: 100 }, sanityConfig)
  const src = urlBuilder(sanityConfig).image(id).url()
  return <Img style={{ marginBottom: '.5em' }} fluid={fluidProps} />
}

export default Image
