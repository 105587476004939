import React from "react"
import Image from '../components/image'
import VideoComponent from '../components/video'
import Slideshow from '../components/slideshow'
import styled from 'styled-components'
import { getFileAsset } from '@sanity/asset-utils';
import { 
  margin, 
  FigureEl, 
  ActionButton 
} from '../styles/helpers'
import { Link } from "gatsby"
import { node } from "prop-types"

const returnCaption = str => str !== undefined ? <figcaption>{str}</figcaption> : false
// put this in .env file or smthg...
const sanityConfig = { projectId: 'ul6999hy', dataset: 'production' }

const serializer = {
  types: {
    figure: props => {
      return (
        <FigureEl>
          {Image(props.node.image.asset._ref)}
          {returnCaption(props.node.caption)}
        </FigureEl>
      )
    },
    slideshow: props => (
      <Slideshow officeImages={false} slides={props.node.slides} />
    ),
    video: props => (
      <VideoComponent data={props} />
    ),
    // todo: pdf/other filetypes?
    // currently this is just for gif...
    fileUpload: props => {
      // hack way to check if gif/jpg...
      if (props.node.file.asset._ref.includes('-gif')) {
        console.log('props?', props)
        const gifSrc = getFileAsset(props.node.file, sanityConfig)
        return (
          <FigureEl>
            <img src={gifSrc.url} />
            {returnCaption(props.node.caption)}
          </FigureEl>
        )
      }
    },
    // cta is always external link
    cta: props => (
      <ActionButton>
        <a href={props.node.link} target="_blank">
          {props.node.text}
        </a>
      </ActionButton>
    ),
    internalCta: props => {
      // todo: add in situ
      if (props.node.link) {
        const docType = props.node.link._type
        switch (docType) {
          case 'project' :
            return (
              <ActionButton>
                <Link to={'/projects/' + props.node.link.slug.current}>
                  {props.node.text}
                </Link>
              </ActionButton>
            )
            break 
          case 'event' :
            return (
              <ActionButton>
                <Link to={'/events/' + props.node.link.slug.current}>
                  {props.node.text}
                </Link>
              </ActionButton>
            )
            break 
          case 'archive' : 
            return (
              <ActionButton>
                <Link to={'/archive/' + props.node.link.slug.current}>
                  {props.node.text}
                </Link>
              </ActionButton>
            )
            break 
          case 'booking' : 
            return (
              <ActionButton>
                <Link to={'/booking/' + props.node.link.slug.current}>
                  {props.node.text}
                </Link>
              </ActionButton>
            )
            break 
          default : 
            return false
        }
      }
      else {
        return (
          <ActionButton inactive={true}>
            <a>
              {props.node.text}
            </a>
          </ActionButton>
        )
      }
    },
    bookingLink: props => (
      <ActionButton>
        <Link to={'/booking'}>{props.node.buttonText}</Link>
      </ActionButton>
    ),
    situLink: props => (
      <ActionButton>
        <Link to={'/insitu'}>{props.node.buttonText}</Link>
      </ActionButton>
    ),
  },
  marks: {
    mailLink: ({mark, children}) => {
      const {slug = {}} = mark
      if (mark.emailAddress) {
        const email = 'mailto:' + mark.emailAddress + '?subject=' + mark.subject
        return <a href={email} target='_blank'>{children}</a>  
      }
    },
    externalLink: ({mark, children}) => {
      const {slug = {}} = mark
      if (mark.href) {
        return <a href={mark.href} target='_blank'>{children}</a>  
      }
    },
    internalLink: ({mark, children}) => {
      const {slug = {}} = mark
      // todo: add in situ if need be
      switch (mark.reference._type) {
        case 'project' :
          return <Link to={'/projects/' + mark.reference.slug.current}>{children}</Link>
          break 
        case 'event' :
          return <Link to={'/events/' + mark.reference.slug.current}>{children}</Link>
          break 
        case 'archive' : 
          return <Link to={'/archive/' + mark.reference.slug.current}>{children}</Link>
          break 
        case 'booking' : 
          return <Link to={'/booking/' + mark.reference.slug.current}>{children}</Link>
          break 
        default : 
          return false
      }
    },
    link: ({mark, children}) => {
      const {slug = {}} = mark
      if (mark.href) {
        return mark.blank ? <a href={mark.href} target='_blank'>{children}</a> : <a href={mark.href}>{children}</a>  
      }
    }
  }
}

export default serializer