const angleFromCatetes = (a, b) => Math.atan(a / b)
const calcHypotenuse = (a, b) => Math.sqrt(a * a + b * b)

const getEyeCoordinates = (eye) => {
  const body = document.querySelector('body')
  const { x, y, width, height } = body.getBoundingClientRect()
  return { x: x + width / 2, y: y + height / 2 }
}

const calcMaxMovement = (eye) => {
  const iris = eye.firstElementChild
  const eyeWidth = eye.getBoundingClientRect().width
  const irisWidth = iris.getBoundingClientRect().width
  return (eyeWidth - irisWidth) / 2
}

const limitOffset = (offset, eye) => {
const maxMovement = calcMaxMovement(eye)
if (calcHypotenuse(offset.x, offset.y) <= maxMovement) {
  return { x: offset.x, y: offset.y }
}

const angle = angleFromCatetes(offset.y, offset.x)
  let x = Math.cos(angle) * maxMovement
  let y = Math.sin(angle) * maxMovement
  x = offset.x < 0 ? -x : x
  y = offset.x < 0 ? -y : y
  return { x, y }
}

const getEyeOffset = (eye, mouse) => {
  const x = (mouse.x - eye.x) * 0.25
  const y = (mouse.y - eye.y) * 0.25
  return { x, y }
}

const moveEye = (eye, mouse) => {
  const center = getEyeCoordinates(eye)
  const offset = getEyeOffset(center, mouse)
  const limitedOffset = limitOffset(offset, eye)
  const iris = eye.firstElementChild
  iris.style.transform = `translate(${limitedOffset.x}px, ${limitedOffset.y}px)`
}

const trackMouse = (event, eye) => {
  const mouse = { x: event.clientX, y: event.clientY }
  moveEye(eye, mouse)
}

export default trackMouse